
/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useState, useRef } from 'react';
import { getTranslation } from './Helper';
import { BuildingOffice2Icon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'
import { render } from '@react-email/render';
import MailAccuse from '../templateMail/MailAccuse';
import MailContact from '../templateMail/MailContact';
import { Puff } from 'svg-loaders-react';
import Notification from './Notification';


function renderHtmlTemplateClient(formData, lang) {
    return render(<MailAccuse data={formData} lang={lang} />, {
        pretty: true,
    })
}

function renderTextTemplateClient(formData, lang) {
    return render(<MailAccuse data={formData} lang={lang} />, {
        plainText: true,
    })
}

function renderHtmlTemplate(formData, lang) {
    return render(<MailContact data={formData} lang={lang} />, {
        pretty: true,
    })
}

function renderTextTemplate(formData, lang) {
    return render(<MailContact data={formData} lang={lang} />, {
        plainText: true,
    })
}


const adresses = [
    {
        key: 'saintmande',
        name: 'Saint-Mandé',
        adresse: '59 avenue du Général de Gaulle',
        cp: '94160',
        ville: 'Saint-Mandé',
        telephone: {
            'fr': '01 45 18 35 65',
            'en': '+33 1 45 18 35 65'
        },
        email: 'solal@cloris-avocat.fr',
    },
    {
        key: 'marseille',
        name: 'Marseille',
        adresse: '48 Cours Puget',
        cp: '13006',
        ville: 'Marseille',
        telephone: {
            'fr': '01 45 18 35 65',
            'en': '+33 1 45 18 35 65'
        },
        email: 'solal@cloris-avocat.fr',
    },
]

const content = {
    heading: {
        'fr': 'Où nous rencontrer ?',
        'en': 'Our Locations'
    },
    locationsintro: {
        'fr': 'Nous recevons uniquement sur rendez-vous dans l\'un de nos deux cabinets, à Saint-Mandé ou à Marseille.',
        'en': 'Consultations are available by appointment only at our offices in Saint-Mandé or Marseille.'
    },
    adresse: {
        'fr': 'Adresse',
        'en': 'Address'
    },
    telephone: {
        'fr': 'Téléphone',
        'en': 'Telephone'
    },
    email: {
        'fr': 'Email',
        'en': 'Email'
    },
    contactez: {
        'fr': 'Contactez-nous',
        'en': 'Get in Touch'
    },
    contacteztexte: {
        'fr': 'Laissez-nous vos coordonnées et votre message, nous vous recontacterons dès que possible.',
        'en': 'Please leave your contact information and message, and we will be in touch as soon as possible.'
    },
    prenom: {
        'fr': 'Prénom',
        'en': 'First Name'
    },
    nom: {
        'fr': 'Nom',
        'en': 'Name'
    },
    message: {
        'fr': 'Votre message',
        'en': 'Message'
    },
    envoyer: {
        'fr': 'Envoyer',
        'en': 'Submit'
    },
    envoi: {
        'fr': '...Envoi',
        'en': '...Submitting'
    },
    sujet: {
        'fr': 'Votre demande de contact avec Maître Solal Cloris',
        'en': 'Your Contact Request with Maître Solal Cloris'
    }
}

function MailTo(email) {
    return 'mailto:' + email;
}

function TelTo(tel) {
    return 'tel:' + tel;
}

const sendEmailClient = async (formData, lang) => {
    try {
        const emailBodyHtml = renderHtmlTemplateClient(formData, lang);
        const emailBodyText = renderTextTemplateClient(formData, lang);
        const emailSubject = getTranslation(content.sujet, lang);

        const response = await fetch(`${process.env.REACT_APP_MAIL_URL}/send-email-client`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                from: `${process.env.REACT_APP_MAIL_SENDER}`,
                to: formData.email,
                subject: emailSubject,
                html: emailBodyHtml,
                text: emailBodyText,
            }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        await response.json();
        return true; // Indicates success
    } catch (error) {
        console.error('Error:', error);
        return false; // Indicates failure
    }
};

const sendEmail = async (formData, lang) => {
    try {
        const emailBodyHtml = renderHtmlTemplate(formData, lang);
        const emailBodyText = renderTextTemplate(formData, lang);

        const response = await fetch(`${process.env.REACT_APP_MAIL_URL}/send-email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                from: `${process.env.REACT_APP_MAIL_SENDER}`,
                to: `${process.env.REACT_APP_MAIL_DEST}`,
                subject: "Un mail a été réçu depuis votre site Internet",
                html: emailBodyHtml,
                text: emailBodyText,
            }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        await response.json();
        return true; // Indicates success
    } catch (error) {
        console.error('Error:', error);
        return false; // Indicates failure
    }
};



export default function Contact({ lang }) {

    const formRef = useRef(null);


    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [canSubmit, setCanSubmit] = useState(true);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!canSubmit) return;

        setShowNotification(false);
        setIsSubmitting(true);
        setCanSubmit(false);

        const formData = {
            prenom: event.target.prenom.value,
            nom: event.target.nom.value,
            email: event.target.email.value,
            telephone: event.target.telephone.value,
            message: event.target.message.value.replace(/\n/g, '<br>'),
        };

        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!emailRegex.test(formData.email)) {
            console.log('Please enter a valid email address.');
            setSubmissionStatus('error');
            setShowNotification(true);
            setIsSubmitting(false);
            setTimeout(() => {
                setCanSubmit(true);
            }, 5000);
            return;
        }

        const minDelay = new Promise(resolve => setTimeout(resolve, 1000));
        const emailSentSuccessfully = await sendEmail(formData, lang);
        sendEmailClient(formData, lang);

        await minDelay;

        if (emailSentSuccessfully) {
            setSubmissionStatus('success');
            if (formRef.current) formRef.current.reset();
        } else {
            setSubmissionStatus('error');
        }

        setShowNotification(true);
        setIsSubmitting(false);
        setTimeout(() => {
            setCanSubmit(true);
        }, 5000);
    };


    return (
        <div className="relative isolate bg-white z-40">
            <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                <div className="relative sm:order-1 order-2 px-6 pb-20 pt-20 sm:pt-20 lg:static lg:px-8 lg:py-18">
                    <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                        <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
                            <svg
                                className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                                        width={200}
                                        height={200}
                                        x="100%"
                                        y={-1}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <path d="M130 200V.5M.5 .5H200" fill="none" />
                                    </pattern>
                                </defs>
                                <rect width="100%" height="100%" strokeWidth={0} fill="white" />
                                <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                                    <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                                </svg>
                                <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
                            </svg>
                        </div>
                        <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg mb-16">
                            <h3 className="text-2xl font-bold tracking-tight text-gray-900">{getTranslation(content.heading, lang)}</h3>
                            <p className="mt-4 text-base text-gray-500">
                                {getTranslation(content.locationsintro, lang)}
                            </p>
                        </div>
                        {adresses.map((item) => (
                            <div key={item.key} className="pb-10">
                                <h3 className="text-xl font-medium tracking-tight text-gray-900">{item.name}</h3>
                                <dl className="mt-5 space-y-1 text-base leading-7 text-gray-600">
                                    <div className="flex gap-x-4">
                                        <dt className="flex-none">
                                            <span className="sr-only">{getTranslation(content.adresse, lang)}</span>
                                            <BuildingOffice2Icon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                                        </dt>
                                        <dd>
                                            {item.adresse}
                                            <br />
                                            {item.cp} {item.ville}
                                        </dd>
                                    </div>
                                    <div className="flex gap-x-4">
                                        <dt className="flex-none">
                                            <span className="sr-only">{getTranslation(content.telephone, lang)}</span>
                                            <PhoneIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                                        </dt>
                                        <dd>
                                            <a className="hover:text-gray-900" href={TelTo(getTranslation(item.telephone, lang))}>
                                                {getTranslation(item.telephone, lang)}
                                            </a>
                                        </dd>
                                    </div>
                                    <div className="flex gap-x-4">
                                        <dt className="flex-none">
                                            <span className="sr-only">{getTranslation(content.email, lang)}</span>
                                            <EnvelopeIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                                        </dt>
                                        <dd>
                                            <a className="hover:text-gray-900" href={MailTo(item.email)}>
                                                {item.email}
                                            </a>
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        ))}
                    </div>
                </div>
                <form onSubmit={handleSubmit} ref={formRef} className="sm:order-2 order-1 px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-18">
                    <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                        <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg mb-16">
                            <h3 className="text-2xl font-bold tracking-tight text-gray-900">{getTranslation(content.contactez, lang)}</h3>
                            <p className="mt-4 text-base text-gray-500">
                                {getTranslation(content.contacteztexte, lang)}
                            </p>
                        </div>
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                            <div>
                                <label htmlFor="prenom" className="block text-sm font-semibold leading-6 text-gray-900">
                                    {getTranslation(content.prenom, lang)} <span className="text-red-500">*</span>
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="prenom"
                                        id="prenom"
                                        autoComplete="given-name"
                                        required
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="nom" className="block text-sm font-semibold leading-6 text-gray-900">
                                    {getTranslation(content.nom, lang)} <span className="text-red-500">*</span>
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="nom"
                                        id="nom"
                                        autoComplete="family-name"
                                        required
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                                    {getTranslation(content.email, lang)} <span className="text-red-500">*</span>
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        autoComplete="email"
                                        required
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="telephone" className="block text-sm font-semibold leading-6 text-gray-900">
                                    {getTranslation(content.telephone, lang)} <span className="text-red-500">*</span>
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="tel"
                                        name="telephone"
                                        id="telephone"
                                        autoComplete="tel"
                                        required
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                                    {getTranslation(content.message, lang)} <span className="text-red-500">*</span>
                                </label>
                                <div className="mt-2.5">
                                    <textarea
                                        name="message"
                                        id="message"
                                        required
                                        rows={6}
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                                        defaultValue={''}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-8 flex justify-end">
                            <span className="mr-3">{isSubmitting && <Puff stroke="rgb(107 114 128)" />}</span>
                            <button
                                type="submit"
                                disabled={isSubmitting || !canSubmit}
                                className={`rounded-md px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${isSubmitting || !canSubmit ? 'bg-gray-400 hover:bg-gray-400 focus-visible:outline-gray-400' : 'bg-sky-600 hover:bg-sky-500 focus-visible:outline-sky-600'}`}
                            >
                                {isSubmitting ? getTranslation(content.envoi, lang) : getTranslation(content.envoyer, lang)}
                            </button>
                        </div>
                        {showNotification && <Notification status={submissionStatus} lang={lang} />}
                    </div>
                </form >
            </div >
        </div >
    )
}
